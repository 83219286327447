import axios, { AxiosRequestConfig, AxiosResponse } from "axios";
import router from "@/router";
import { useUserStore } from "@/store/user";
import {
  notifications,
  NotificationType,
  pushNotification,
} from "@/composables/notification";

interface Subscriber {
  (token: string): void;
}

const baseURL = process.env.VUE_APP_STRAPI_API_URL
  ? process.env.VUE_APP_STRAPI_API_URL
  : ``;
export const axiosClient = axios.create({
  baseURL,
  headers: {
    Accept: "application/json",
    "Content-Type": "application/json",
  },
});
let subscribers: Subscriber[] = [];
let isRefreshing = false;

function onRefreshed(accessToken: string): void {
  subscribers.map((cb) => cb(accessToken));
}

function subscribeTokenRefresh(cb: Subscriber): void {
  subscribers.push(cb);
}

axiosClient.interceptors.request.use(
  (request) => {
    const userStore = useUserStore();
    if (request.headers && userStore.accessToken) {
      request.headers.Authorization = `Bearer ${userStore.accessToken}`;
    }
    return request;
  },
  (error) => {
    return Promise.reject(error);
  }
);

axiosClient.interceptors.response.use(
  function (response) {
    return response;
  },
  function (error) {
    const userStore = useUserStore();
    const originalRequest = error.config;
    const refreshToken: string | null = userStore.refreshToken;
    if (error.response.status === 401) {
      if (refreshToken) {
        if (!isRefreshing) {
          isRefreshing = true;
          axios
            .post(`${baseURL}/auth/refresh-token`, {
              refreshToken: refreshToken,
            })
            .then((response) => {
              const { accessToken, refreshToken } =
                response.data.data.attributes;
              userStore.setTokens({ accessToken, refreshToken });
              isRefreshing = false;
              onRefreshed(accessToken);
              subscribers = [];
            })
            .catch(() => {
              userStore.clear();
              router.push("/");
            });
        }
        return new Promise((resolve) => {
          subscribeTokenRefresh((token) => {
            originalRequest.headers.Authorization = `Bearer ${token}`;
            resolve(axiosClient(originalRequest));
          });
        });
      } else {
        userStore.clear();
        router.push("/");
        pushNotification({
          text: "Token incorrect, please login",
          type: NotificationType.Failed,
          key: `key${notifications.value.length}`,
        });
      }
    }
    if (error.response.status === 413) {
      pushNotification({
        text: "Request Entity Too Large, please try again",
        type: NotificationType.Failed,
        key: `key${notifications.value.length}`,
      });
    }
    return Promise.reject(error);
  }
);

const parse = <DataT>(res: AxiosResponse<DataT>) => {
  return res.data;
};

export const ApiService = {
  removeHeader() {
    axiosClient.defaults.headers.common = {};
  },

  get<DataT>(resource: any, config?: AxiosRequestConfig): Promise<DataT> {
    return axiosClient.get(resource, config).then(parse);
  },

  post<DataT>(
    resource: any,
    data?: any,
    config?: AxiosRequestConfig
  ): Promise<DataT> {
    return axiosClient.post(resource, data, config).then(parse);
  },

  put<DataT>(resource: any, data: any, config?: AxiosRequestConfig) {
    return axiosClient.put(resource, data, config);
  },

  patch(resource: any, data: any, config?: AxiosRequestConfig) {
    return axiosClient.patch(resource, data, config);
  },

  delete<DataT>(resource: any, config?: AxiosRequestConfig) {
    return axiosClient.delete(resource, config);
  },

  customRequest(data: any) {
    return axiosClient(data);
  },
};
